/************************************************************************************************************/

( function () {

	"use strict";

	var backToTopVisible = false;

	jQuery( document ).ready( function () {

		// Setup the mobile menu button
		jQuery( '.mobile-menu-button' ).bind( 'click', function () {

			jQuery( '.mobile-menu-overlay' ).css( { 'display': 'block' } );
			jQuery( '.mobile-menu' ).css( { 'left': 0 } );
			return false;

		} );

		// Setup the mobile menu overlay
		jQuery( '.mobile-menu-overlay' ).bind( 'click', function () {

			jQuery( '.mobile-menu-overlay' ).css( { 'display': 'none' } );
			jQuery( '.mobile-menu' ).css( { 'left': -260 } );

		} );

		// Setup the mobile menu close utton
		jQuery( '.mobile-menu-close-button' ).bind( 'click', function () {

			jQuery( '.mobile-menu-overlay' ).css( { 'display': 'none' } );
			jQuery( '.mobile-menu' ).css( { 'left': -260 } );

		} );

		// Setup the mobile menu links
		jQuery( '.mobile-menu li.menu-item-has-children > a ' ).bind( 'click', function () {

			jQuery( this ).parent().toggleClass( 'active' );

			var subMenu = jQuery( this ).next();

			if ( jQuery( this ).parent().hasClass( 'active' ) ) {
				var height = subMenu.height();
				subMenu.css( { 'height': 0, 'display': 'block' } );
				subMenu.height( height );
			} else {
				subMenu.removeAttr( 'style' );
			}

			return false;

		} );

		// Setup the mobile bottom bar search button
		jQuery( '.footer-bar .fa-search' ).bind( 'click', function() {

			jQuery( '.mobile-menu-overlay' ).css( { 'display': 'block' } );
			jQuery( '.mobile-menu' ).css( { 'left': 0 } );
			jQuery( '.mobile-menu #s' ).focus();
			return false;

		} )

		;
		jQuery( '.back-to-top' ).click( function () {
			jQuery( 'html,body' ).animate( { scrollTop: 0 }, 500, 'easeInOutQuart' );
		} );

		jQuery( '.wp-block-cover:has(a)' )
			.addClass( 'has-link' )
			.on( 'click', function () {
				document.location.href = jQuery( this ).find( 'a' )[0].href;
			} );

		jQuery( window ).scroll( bootiqueCheckBackToTop );
		jQuery( window ).resize( bootiqueCheckBackToTop );
		bootiqueCheckBackToTop();

		// Auto-populate the subject field of the enquiry form if a value is passed in the querstring
		var subject = getUrlParameter( 'subject' );
		if( subject!='' ) {
			subject = subject.replace( '&amp;', '&' );
			jQuery( '[name="Subject"], [name="subject"]' )[0].value = subject;
		}

	} );

	function bootiqueCheckBackToTop() {
		if ( 0 < ( jQuery( 'body' ).scrollTop() + jQuery( 'html' ).scrollTop() ) ) {
			if ( !backToTopVisible ) {
				backToTopVisible = true;
				jQuery( '.back-to-top' ).animate( { 'bottom': 0 }, 500, 'easeInOutQuart' );
			}
		} else {
			if ( backToTopVisible ) {
				backToTopVisible = false;
				jQuery( '.back-to-top' ).animate( { 'bottom': -40 }, 500, 'easeInOutQuart' );
			}
		}
	}

}() );

	function getUrlParameter( name ) {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		var results = regex.exec(location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	};

/************************************************************************************************************/